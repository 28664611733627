// extracted by mini-css-extract-plugin
export var Categories = "Categories-module--Categories--a923a";
export var CategoriesList = "Categories-module--CategoriesList--d3aeb";
export var CategoryBlock = "Categories-module--CategoryBlock--6d9c1";
export var CategoryButton = "Categories-module--CategoryButton--da9f2";
export var CategoryDescription = "Categories-module--CategoryDescription--14a9d";
export var CategoryImage = "Categories-module--CategoryImage--40dfc";
export var CategoryTitle = "Categories-module--CategoryTitle--c20f7";
export var DashboardListing = "Categories-module--DashboardListing--062d2";
export var MoreLink = "Categories-module--MoreLink--cf016";
export var PartialListing = "Categories-module--PartialListing--c663b";
export var Tag = "Categories-module--Tag--a4418";
export var Tags = "Categories-module--Tags--9df98";
export var Wrapper = "Categories-module--Wrapper--e11fc";