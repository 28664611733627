// extracted by mini-css-extract-plugin
export var BusinessName = "Dashboard-module--BusinessName--670ec";
export var Button = "Dashboard-module--Button--1d4ae";
export var Capitalize = "Dashboard-module--Capitalize--5cf65";
export var CenterAligned = "Dashboard-module--CenterAligned--3093b";
export var DownloadButton = "Dashboard-module--DownloadButton--d6e96";
export var Entries = "Dashboard-module--Entries--2bae7";
export var EntryRow1 = "Dashboard-module--EntryRow1--67ff0";
export var EntryRow2 = "Dashboard-module--EntryRow2--12024";
export var EntryWrapper = "Dashboard-module--EntryWrapper--59221";
export var Finalise = "Dashboard-module--Finalise--afcf2";
export var HeroName = "Dashboard-module--HeroName--5c9e0";
export var Intro = "Dashboard-module--Intro--8e47d";
export var JobTitle = "Dashboard-module--JobTitle--f3ced";
export var LoginToggleBoxes = "Dashboard-module--LoginToggleBoxes--5a619";
export var LoginToggles = "Dashboard-module--LoginToggles--5e58a";
export var Right = "Dashboard-module--Right--8141e";
export var SmallText = "Dashboard-module--SmallText--53a61";
export var ToggleBoxActive = "Dashboard-module--ToggleBoxActive--76c2d";
export var ToggleContent = "Dashboard-module--ToggleContent--169cd";